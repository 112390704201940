import React from "react"
import { Link } from "gatsby"
import { RiArrowLeftSLine, RiMailCheckLine } from "react-icons/ri"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import * as styles from './tack.module.scss'

const ThankYou = () => (
    <Layout className="thanks-page" hideBottomPage={true}>
        <SEO title="Kontakta Oss | Citadellet Bolagsservice AB" />
        <div className={styles.thanksContainer}>
            <RiMailCheckLine style={{
                fontSize: "130px",
                color: '#6da034'
            }} />
            <div className={styles.title}>
                Tack, ditt meddelande har skickats.
          </div>
            <div className={styles.text}>
                Vi återkommer till er så snart vi kan.
            </div>
            <div className={styles.text}>
                Det går också bra att ringa oss på <span className={styles.phone}> 08-23 01 23. </span>
            </div>
            <div className={styles.linkWrapper}>
                <Link to="/kontakta-oss" className={styles.linkto} >
                    <RiArrowLeftSLine style={{ fontSize: 35 }} />
                    Gå tillbaka till formuläret
                </Link>
            </div>
        </div>
    </Layout>
)

export default ThankYou;